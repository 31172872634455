var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && !_vm.isAvailable,
              expression: "!isLoading && !isAvailable"
            }
          ],
          attrs: { column: "", wrap: "" }
        },
        [
          _c("p", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.errorMessage))]),
          _c(
            "v-btn",
            {
              staticClass: "font-weight-black float-right mr-4",
              staticStyle: { "font-size": "20px" },
              attrs: {
                width: "70vw",
                "max-width": "350px",
                color: "next",
                dark: ""
              },
              on: {
                click: function($event) {
                  return _vm.onClickiBackHomeButton()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("button.backHome")))]
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && _vm.isAvailable,
              expression: "!isLoading && isAvailable"
            }
          ],
          attrs: { column: "" }
        },
        _vm._l(_vm.images, function(image, index) {
          return _c(
            "div",
            { key: index },
            [
              image != null && image.length > 0
                ? _c("v-img", {
                    attrs: {
                      contain: "",
                      src: image,
                      "aspect-ratio": _vm.aspect
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout v-show="!isLoading && !isAvailable" column wrap>
      <p class="mt-2">{{ errorMessage }}</p>
      <v-btn
        class="font-weight-black float-right mr-4"
        width="70vw"
        max-width="350px"
        color="next"
        style="font-size:20px"
        dark
        @click="onClickiBackHomeButton()"
        >{{ $t('button.backHome') }}</v-btn
      >
    </v-layout>
    <v-layout v-show="!isLoading && isAvailable" column>
      <div v-for="(image, index) in images" :key="index">
        <v-img
          v-if="image != null && image.length > 0"
          contain
          :src="image"
          :aspect-ratio="aspect"
        />
      </div>
    </v-layout>
  </v-container>
</template>
<script>
import {
  convertBlobToBase64,
  getPdfImage,
  getPdfWidthAndHeight,
} from '@/lib/commonUtil';
import { mapGetters } from 'vuex';

export default {
  name: 'PdfPreviewTemplate',

  props: {
    isAvailable: Boolean,
    pdfBlob: Blob,
    errorMessage: String,
  },
  computed: {
    isLoading() {
      return this.loading();
    },
  },

  data: () => ({
    // 画像群
    images: [],

    // アスペクト比
    aspect: null,
  }),
  async mounted() {},
  methods: {
    ...mapGetters('ui', ['loading']),

    // ホーム画面に戻るボタン押下時
    onClickiBackHomeButton() {
      // ホーム画面に遷移する
      this.$router.push('/Home');
    },
  },
  watch: {
    async pdfBlob(newBlob) {
      // PDFが取得できなかった場合、以降の処理を中止する
      if (!newBlob) return;

      const base64 = await convertBlobToBase64(newBlob);
      // PDFの各ページの画像を取得する
      const images = await getPdfImage(base64);
      // PDFのサイズを取得する
      const size = await getPdfWidthAndHeight(base64);
      // PDFの画像群をセットする
      this.$set(this, 'images', images);
      // PDFのアスペクト比をセットする
      this.$set(this, 'aspect', size.width / size.height);
    },
  },
};
</script>
